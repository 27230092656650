import styled from 'styled-components'
import variables from '../variables'
import { Heading, Input } from '../../components'
import { headerStyles } from '../global'
import { ReactComponent as LoadingPink } from '../../../assets/loading-pink.svg'

export const Container = styled.div`
  width: 100%;
  padding: 70px;
  background: ${variables.colors.silverTree.darker};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${variables.media.sm} {
    padding: 150px;
  }
`

export const PageHeading = styled(Heading)`
  ${headerStyles.headerMedium};
  color: white;
`

export const SectionText = styled.p`
  margin: 0 0 1.5rem 0;
  color: ${variables.colors.silverTree.light};
`

export const StyledForm = styled.form`
  text-align: center;
`

export const Message = styled.p`
  color: white;
  font-size: 1.5em;
  font-style: italic;
  text-align: center;
`

export const LoadingIcon = styled(LoadingPink)`
  width: 16px;
  height: 16px;
  animation: fadeout 2s ease-in-out;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledInput = styled(Input)`
  border-radius: 6px;
`
