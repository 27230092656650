import React from 'react'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import * as styles from '../styles/pages/join-slack'
import { Button } from '../components'

export default function JoinReddit() {
  return (
    <PageLayout
      sharingTitle="Aito.ai - Join aito reddit"
      description="Join the Aito community on Reddit. Discuss with your peers or with anybody from the Aito team"
    >
      <Helmet>
        <title>Aito.ai - Join aito reddit</title>
      </Helmet>
      <styles.Container>
        <styles.PageHeading>Join us on Reddit</styles.PageHeading>
        <styles.SectionText>
          Click the button below to join our Reddit community.
        </styles.SectionText>
        <a href="https://www.reddit.com/r/aitoai/" target="_blank" rel="noopener noreferrer">
          <Button>
            Join Reddit
          </Button>
        </a>
      </styles.Container>
    </PageLayout>
  )
}
